import {Injectable} from '@angular/core';
import {
  WpRelationInlineCreateService
} from 'core-app/features/work-packages/components/wp-relations/embedded/relations/wp-relation-inline-create.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class DbWorkPackageLinkNoActionsService extends WpRelationInlineCreateService {
  public dbSpecific = false;

  public get canAdd():Observable<boolean> {
    if (this.dbSpecific) {
      return of(false);
    } else {
      return super.canAdd;
    }
  }
}
