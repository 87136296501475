<div class="attributes-group--header">
  <div class="attributes-group--header-container">
    <h3 class="attributes-group--header-text"
        [textContent]="group.name"></h3>
  </div>
</div>

<wp-embedded-table #embeddedTable
                   [initialLoadingIndicator]="false"
                   [queryProps]="queryProps"
                   [tableActions]="tableActions"
                   [configuration]="{ actionsColumnEnabled: true,
                                      hierarchyToggleEnabled: false,
                                      inlineCreateEnabled: true,
                                      compactTableStyle: true,
                                      columnMenuEnabled: false,
                                      contextMenuEnabled: false,
                                      projectIdentifier: workPackage.project.id,
                                      projectContext: false }" >
</wp-embedded-table>

<db-wp-link-document-version [workPackage]="workPackage"
                             [query]="query"
                             *ngIf="applicable && processIdSet">
</db-wp-link-document-version>

<div *ngIf="applicable && !processIdSet"
     class="db-process_id-first">
  <op-icon icon-classes="icon-info1 -padded"></op-icon>
  <span [textContent]="displayProcessIdUnset"></span>
</div>
