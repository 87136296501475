<div class="spot-modal
            spot-modal_wide
            loading-indicator--location
            db-link-version--modal"
     data-qa-selector="db-link-document-version-modal"
>
  <div class="spot-modal--header">
    <span class="spot-modal--header-title">{{text.title}}</span>
  </div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container"
       tabindex="0">

    <form class="form -wide-labels"
          *ngIf="versionsLoaded">
      <section class="form--section">
        <div class="form--field">
          <label class="form--label"
                 [textContent]="text.linkSelection.explanation">
          </label>
          <div class="form--field-container -vertical">

            <label class="form--label-with-check-box">
              <div class="form--check-box-container">
                <input type="radio"
                       [(ngModel)]="linkMode"
                       [ngModelOptions]="{standalone: true}"
                       [disabled]="!versionsExist"
                       value="existing"
                       name="link_mode_switch"
                       class="form--radio-button">
              </div>

              {{ text.linkSelection.existing }}
            </label>

            <label class="form--label-with-check-box">
              <div class="form--check-box-container">
                <input type="radio"
                       [(ngModel)]="linkMode"
                       [ngModelOptions]="{standalone: true}"
                       [disabled]="!versionsExist"
                       value="copy"
                       name="link_mode_switch">
              </div>

              {{ text.linkSelection.copy }}
            </label>

            <label class="form--label-with-check-box">
              <div class="form--check-box-container">
                <input type="radio"
                       [(ngModel)]="linkMode"
                       [ngModelOptions]="{standalone: true}"
                       value="upload"
                       name="link_mode_switch"
                       class="form--radio-button"
                       id="db-document-version-link-upload">
              </div>

              {{ text.linkSelection.upload }}
            </label>
          </div>
        </div>
      </section>

      <section class="form--section"
               *ngIf="linkMode === 'existing' || linkMode === 'copy'">
        <div class="form--field">
          <label for="db-document-version-link"
                 class="form--label"
                 [textContent]="text.currentlyExisting">
          </label>
          <div class="form--field-container">
            <div class="form--text-field-container">
              <ng-select [(ngModel)]="selectedVersion"
                         [ngModelOptions]="{standalone: true}"
                         [items]="availableVersions"
                         [searchFn]="hasTermInVersionCf()"
                         [loading]="!availableVersions"
                         [isOpen]="true"
                         #selectedVersionModel="ngModel"
                         required
                         labelForId="db-document-version-link">
                <ng-template ng-label-tmp let-item="item">
                    <span class="db-version-selection--status"
                          [ngClass]="statusHtmlClass(item)"
                          [title]="displayedStatusName(item)">
                    </span>

                  <span class="db-version-selection--version">
                      {{ displayedVersionNumber(item) }}
                    </span>
                </ng-template>

                <ng-template ng-option-tmp
                             let-item="item"
                             let-index="index"
                             class="db-version-selection--option">
                    <span class="db-version-selection--status"
                          [ngClass]="statusHtmlClass(item)"
                          [title]="displayedStatusName(item)">
                    </span>

                  <span class="db-version-selection--version">
                      {{ displayedVersionNumber(item) }}
                    </span>

                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="form--field-instructions"
               [ngClass]="{'-error': selectedVersionModel.invalid}"
               [textContent]="displaySelectedVersionErrorMessage">
          </div>
        </div>
      </section>

      <section class="form--section"
               *ngIf="linkMode === 'upload'">

        <op-attachments [resource]="attachmentUploadDummyWP"
                        [allowUploading]="($attachmentSet | async) === false">
        </op-attachments>
        <div class="form--field-instructions"
             [textContent]="displayUploadNotificationMessage">
        </div>
      </section>

      <section class="form--section"
               *ngIf="linkMode === 'upload' || linkMode === 'copy'">
        <div class="form--field">
          <label for="link-new-version-number-template"
                 class="form--label"
                 [textContent]="text.newVersionNumber">
          </label>
          <div class="form--field-container">
            <div class="form--text-field-container">
              <input type="number"
                     [(ngModel)]="newVersionNumber"
                     [ngModelOptions]="{standalone: true}"
                     class="form--text-field"
                     [dbTakenVersions]="takenVersionNumbers"
                     required
                     #newVersionNumberModel="ngModel"
                     id="link-new-version-number-template">
            </div>
          </div>
          <div class="form--field-instructions"
               [ngClass]="{'-error': newVersionNumberModel.invalid}"
               [textContent]="displayNewVersionErrorMessage">
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="spot-action-bar db-link-version--link-button">
    <div class="spot-action-bar--right">
      <button (click)="closeMe()"
              class="button button_no-margin spot-action-bar--action"
              [textContent]="text.buttons.abort">
      </button>
      <button [disabled]="!isSubmittable"
              (click)="submit()"
              class="button -highlight db-link-version--link-button spot-action-bar--action"
              [textContent]="text.buttons.link">

      </button>
    </div>
  </div>
</div>
