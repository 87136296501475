import { Injectable, Injector } from '@angular/core';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { OpModalService } from 'core-app/shared/components/modal/modal.service';
import {
  DocumentVersionLinkingSelection, LinkDocumentVersionModal,
} from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/modals/link-document-version/link-document-version.modal';

@Injectable()
export class LinkDocumentVersionService {
  constructor(readonly opModalService:OpModalService,
    readonly injector:Injector) {
  }

  public getVersion(workPackage:WorkPackageResource, typeId:string) {
    return new Promise<DocumentVersionLinkingSelection|null>((resolve, reject) => {
      this.opModalService.show(LinkDocumentVersionModal, this.injector, {
        workPackage,
        typeId,
      }).subscribe((modal:LinkDocumentVersionModal) => {
        modal.closingEvent.subscribe((modalAboutToClose:LinkDocumentVersionModal) => {
          if (modalAboutToClose.userSelection) {
            resolve(modalAboutToClose.userSelection);
          } else {
            reject();
          }
        });
      });
    });
  }
}
