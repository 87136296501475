import {Directive, Input} from '@angular/core';
import {Validator, ValidatorFn, AbstractControl} from '@angular/forms';
import {NG_VALIDATORS} from '@angular/forms';

export function forbiddenNameValidator(takenVersions:number[]):ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isTaken = takenVersions.indexOf(control.value) !== -1;

    return isTaken ? {'versionTaken': {value: control.value}} : null;
  };
}

@Directive({
  selector: '[dbTakenVersions]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DbTakenVersionValidatorDirective, multi: true }]
})
export class DbTakenVersionValidatorDirective implements Validator {
  @Input('dbTakenVersions') takenVersions:number[];

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.takenVersions ? forbiddenNameValidator(this.takenVersions)(control)
      : null;
  }


}