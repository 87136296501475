import {Injectable} from '@angular/core';
import {WorkPackageResource} from 'core-app/features/hal/resources/work-package-resource';
import {Meta} from '@angular/platform-browser'

type DbConfigurationRaw = {
  domainDocumentType:string;
  technicalDocumentType:string;
  orderType:string;
  processIdCustomFields:string;
  versionCustomField:string;
}

type DbConfigurationParsed = {
  domainDocumentType:number;
  technicalDocumentType:number;
  orderType:number;
  processIdCustomFields:[number];
  versionCustomField:number;
}

@Injectable()
export class DbConfigurationService {
  private _persistedConfiguration:DbConfigurationParsed;

  constructor(private readonly meta:Meta) {
  }

  isOrderType(workPackage:WorkPackageResource) {
    let applicableOrderTypeId = this.configuration.orderType;
    let currentTypeId = parseInt(workPackage.type.id!);

    return applicableOrderTypeId === currentTypeId
  }

  hasProcessId(workPackage:WorkPackageResource):boolean {
    return this.processIdCustomFieldName(workPackage) !== undefined;
  }

  processIdCustomFieldName(workPackage:WorkPackageResource) {
    const cfIdCandidates = this
      .configuration
      .processIdCustomFields
      .filter((id: number) => workPackage.hasOwnProperty(`customField${id}`));

    if (cfIdCandidates.length > 1) {
      alert('The installation/project is misconfigured. There is more than one custom field denoting the process_id active in this project.');
    } else if (cfIdCandidates.length === 0) {
      alert('The installation/project is misconfigured. There is no custom field denoting the process_id active in this project.')
    }

    return `customField${cfIdCandidates[0]}`
  }

  versionCustomFieldName() {
    return `customField${this.configuration.versionCustomField}`
  }

  get configuration() {
    if (this._persistedConfiguration) { return this._persistedConfiguration }

    let configurationTag = this.meta.getTag("name='db-plugin'")

    if (!configurationTag) {
      alert('No Configuration for the Deutsche Bahn plugin found');
    } else {
      this._persistedConfiguration = this.parseTagDataset(configurationTag.dataset as DbConfigurationRaw);
    }

    return this._persistedConfiguration;
  }

  parseTagDataset(configurationTag:DbConfigurationRaw):DbConfigurationParsed {
    return {
      domainDocumentType: parseInt(configurationTag.domainDocumentType),
      technicalDocumentType: parseInt(configurationTag.technicalDocumentType),
      orderType: parseInt(configurationTag.orderType),
      processIdCustomFields: JSON.parse(configurationTag.processIdCustomFields),
      versionCustomField: parseInt(configurationTag.versionCustomField),
    }
  }
}
