import {Injectable} from '@angular/core';
import {PathHelperService} from 'core-app/core/path-helper/path-helper.service';

@Injectable()
export class DbPathHelperService {
  constructor(protected corePathHelper:PathHelperService) {
  }

  public get apiBasePath() {
    return `${this.corePathHelper.api.v3.apiV3Base}/deutsche_bahn`
  }

  public get webhook() {
    return this.apiBasePath + '/webhook';
  }
}
