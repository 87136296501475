import {ChangeDetectorRef, Component, Inject, ViewEncapsulation} from '@angular/core';
import { WorkPackageInlineCreateService } from 'core-app/features/work-packages/components/wp-inline-create/wp-inline-create.service';
import {DbConfigurationService} from '../../../db-configuration/db-configuration.service';
import {
  DbWorkPackageLinkNoActionsService
} from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/wp-relations/wp-link-no-actions/wp-link-no-actions.service';
import {
  WpRelationInlineCreateService
} from 'core-app/features/work-packages/components/wp-relations/embedded/relations/wp-relation-inline-create.service';
import {
  WorkPackageNotificationService
} from 'core-app/features/work-packages/services/notifications/work-package-notification.service';
import {UrlParamsHelperService} from 'core-app/features/work-packages/components/wp-query/url-params-helper';
import {HalEventsService} from 'core-app/features/hal/services/hal-events.service';
import {SchemaCacheService} from 'core-app/core/schemas/schema-cache.service';
import {
  WorkPackageRelationQueryComponent
} from 'core-app/features/work-packages/components/wp-relations/embedded/relations/wp-relation-query.component';
import {HalResource} from 'core-app/features/hal/resources/hal-resource';
import {I18nService} from 'core-app/core/i18n/i18n.service';
import {PathHelperService} from 'core-app/core/path-helper/path-helper.service';
import {
  WorkPackageRelationsService
} from 'core-app/features/work-packages/components/wp-relations/wp-relations.service';

@Component({
  selector: 'db-wp-relation-query',
  templateUrl: './wp-relation-query.html',
  styleUrls: ['./wp-relation-query.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: WorkPackageInlineCreateService, useClass: DbWorkPackageLinkNoActionsService }
  ]
})
export class DbWorkPackageRelationQueryComponent extends WorkPackageRelationQueryComponent {
  applicable:boolean = false;
  processIdCf:string;
  configuration:HalResource;

  constructor(protected readonly PathHelper:PathHelperService,
              @Inject(WorkPackageInlineCreateService) protected readonly wpInlineCreate:WpRelationInlineCreateService,
              protected readonly wpRelations:WorkPackageRelationsService,
              protected readonly halEvents:HalEventsService,
              protected readonly queryUrlParamsHelper:UrlParamsHelperService,
              protected readonly cdRef:ChangeDetectorRef,
              protected readonly wpNotifications:WorkPackageNotificationService,
              protected readonly schemaCache:SchemaCacheService,
              protected readonly I18n:I18nService,
              protected dbConfiguration:DbConfigurationService) {
    super(PathHelper,
          wpInlineCreate,
          wpRelations,
          halEvents,
          queryUrlParamsHelper,
          wpNotifications,
          I18n)
  }

  ngOnInit() {
    super.ngOnInit();

    this.processIdCf = this.dbConfiguration.processIdCustomFieldName(this.workPackage);
    this.applicable = this.dbConfiguration.isOrderType(this.workPackage) && this.dbConfiguration.hasProcessId(this.workPackage);

    if (this.applicable) {
      this.tableActions = [];
      (this.wpInlineCreate as DbWorkPackageLinkNoActionsService).dbSpecific = true;
    }

    this.cdRef.detectChanges();
  }

  public refreshTable() {
    this.embeddedTable && super.refreshTable();
  }

  public get processIdSet() {
    return !!this.processIdCf && !!this.workPackage[this.processIdCf];
  }

  public get displayProcessIdUnset() {
    let name = this.schemaCache.of(this.workPackage)[this.processIdCf].name

    return this.I18n.t('js.deutsche_bahn.errors.process_id_first', { name: name });
  }
}
