import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenprojectWorkPackagesModule } from 'core-app/features/work-packages/openproject-work-packages.module';
import { OpenprojectAttachmentsModule } from 'core-app/shared/components/attachments/openproject-attachments.module';
import { HookService } from 'core-app/features/plugins/hook-service';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { GroupDescriptor } from 'core-app/features/work-packages/components/wp-single-view/wp-single-view.component';

import { DbConfigurationService } from './components/db-configuration/db-configuration.service';
import { LinkDocumentVersionModal } from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/modals/link-document-version/link-document-version.modal';
import { DbTakenVersionValidatorDirective } from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/modals/link-document-version/taken-version-validator.directive';
import { DbWorkPackageLinkDocumentVersionComponent } from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/wp-relations/wp-link-document-version/wp-link-document-version.component';
import { LinkDocumentVersionService } from './components/modals/link-document-version/link-document-version.service';
import { DbWorkPackageRelationQueryComponent } from './components/wp-relations/embedded/relations/wp-relation-query.component';
import { DbPathHelperService } from './common/path-helper.service';
import { DbWebhookButtonComponent } from './components/webhooks/webhook-button.component';
import { IconModule } from 'core-app/shared/components/icon/icon.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

export function initializeDBPlugin(injector:Injector) {
  return () => {
    const hookService = injector.get(HookService);

    hookService.register('attributeGroupComponent', (group:GroupDescriptor, workPackage:WorkPackageResource) => {
      if (!workPackage.isNew && group.type === 'WorkPackageFormRelationQueryGroup' && ['Fachkonzept', 'DV-Konzept'].includes(group.name)) {
        return DbWorkPackageRelationQueryComponent;
      }
      return null;
    });

    hookService.register('openProjectAngularBootstrap', () => [
      { selector: 'db-webhook-button', cls: DbWebhookButtonComponent },
    ]);
  };
}

@NgModule({
  imports: [
    FormsModule,
    NgSelectModule,
    CommonModule,

    IconModule,

    OpenprojectWorkPackagesModule,

    OpenprojectAttachmentsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER, useFactory: initializeDBPlugin, deps: [Injector], multi: true,
    },
    DbConfigurationService,
    DbPathHelperService,
    LinkDocumentVersionService,
  ],
  declarations: [
    LinkDocumentVersionModal,
    DbWorkPackageRelationQueryComponent,
    DbWorkPackageLinkDocumentVersionComponent,

    DbTakenVersionValidatorDirective,

    DbWebhookButtonComponent,
  ],
})
export class PluginModule {
}
