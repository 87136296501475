import {Component, Input} from '@angular/core';
import {StateService} from '@uirouter/core';
import {ToastService} from 'core-app/shared/components/toaster/toast.service';
import {DocumentVersionLinkingSelection} from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/modals/link-document-version/link-document-version.modal';
import {WorkPackageNotificationService} from 'core-app/features/work-packages/services/notifications/work-package-notification.service';
import {QueryResource} from 'core-app/features/hal/resources/query-resource';
import {
  LinkDocumentVersionService
} from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/modals/link-document-version/link-document-version.service';
import {WorkPackageResource} from 'core-app/features/hal/resources/work-package-resource';
import {HalResourceService} from 'core-app/features/hal/services/hal-resource.service';
import {
  WorkPackagesActivityService
} from 'core-app/features/work-packages/components/wp-single-view-tabs/activity-panel/wp-activity.service';
import {I18nService} from 'core-app/core/i18n/i18n.service';
import {PathHelperService} from 'core-app/core/path-helper/path-helper.service';

@Component({
  selector: 'db-wp-link-document-version',
  templateUrl: './wp-link-document-version.component.html',
  styles: ['a { font-size: 0.875em; margin-top: 0.125em }']
})
export class DbWorkPackageLinkDocumentVersionComponent {
  @Input() workPackage:WorkPackageResource;
  @Input() query:QueryResource;

  applicable:boolean = true;

  text = { name: this.i18n.t('js.deutsche_bahn.link_document_version'),
           success: this.i18n.t('js.notice_successful_update')};

  constructor(private halResourceService:HalResourceService,
              private wpActivity:WorkPackagesActivityService,
              private wpNotificationsService:WorkPackageNotificationService,
              private notifications:ToastService,
              private pathHelper:PathHelperService,
              private linkDocumentVersionService:LinkDocumentVersionService,
              readonly state:StateService,
              private i18n:I18nService) { }

  public update() {
    // Fetch the filter used in the query in order to know which type to filter for
    let typeId = this.typeFilterHref.split('/').pop();

    this.linkDocumentVersionService
      .getVersion(this.workPackage, typeId)
      .then((userSelection) => {
        if (!userSelection) {
          return;
        }

        this.halResourceService
          .post<any>(this.linkUrl,
                     this.linkPayload(userSelection))
          .toPromise()
          .then(() => {
            this.state.go('.', this.state.params, { reload: true });
            this.notifications.addSuccess(this.text.success);
          })
          .catch((response) => {
            this.wpNotificationsService.handleRawError(response, this.workPackage);
          });

      }).catch(() => {
        // no version selected
      });
  }

  public get title() {
    return this.name;
  }

  public get name() {
    return this.text.name;
  }

  private get linkUrl() {
    return `${this.pathHelper.staticBase}/api/v3/deutsche_bahn/orders/${this.workPackage.id}/link`;
  }

  private linkPayload(userSelection:DocumentVersionLinkingSelection) {
    let payload:any = {
      mode: userSelection.mode,
      _links: {
        type: {
          href: this.typeFilterHref
        }
      }
    };

    switch (userSelection.mode) {
      case('existing'):
        payload['existingVersionNumber'] = userSelection.chosenVersion;
        break;
      case('copy'):
        payload['templateVersionNumber'] = userSelection.chosenVersion;
        payload['newVersionNumber'] = userSelection.newVersion;
        break;
      case('upload'):
        payload['newVersionNumber'] = userSelection.newVersion;
        payload['_links']['attachment'] = { href: `${this.pathHelper.staticBase}/api/v3/attachments/${userSelection.attachmentId}` };
        break;
    }

    return payload;
  }

  private get typeFilterHref() {
    let typeFilterPath = `${this.pathHelper.staticBase}/api/v3/queries/filters/type`;

    let typeFilter = this.query.filters.find((element) => element.$links.filter.href == typeFilterPath);

    return typeFilter!.$links.values[0].href;
  }
}
