import {
  Component, ElementRef, Inject, ChangeDetectorRef, ViewChild, ViewEncapsulation, OnInit, ChangeDetectionStrategy,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { OpModalComponent } from 'core-app/shared/components/modal/modal.component';
import { OpModalLocalsMap } from 'core-app/shared/components/modal/modal.types';
import {
  DbConfigurationService,
} from 'core-app/features/plugins/linked/openproject-deutsche_bahn/components/db-configuration/db-configuration.service';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { LoadingIndicatorService } from 'core-app/core/loading-indicator/loading-indicator.service';
import { OpModalLocalsToken } from 'core-app/shared/components/modal/modal.service';
import { AttachmentsResourceService } from 'core-app/core/state/attachments/attachments.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { IAttachment } from 'core-app/core/state/attachments/attachment.model';

export interface DocumentVersionLinkingSelection {
  mode:'existing'|'copy'|'upload'
  chosenVersion?:number
  newVersion?:number
  attachmentId?:number
}

@Component({
  templateUrl: './link-document-version.modal.html',
  styleUrls: ['./link-document-version.modal.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDocumentVersionModal extends OpModalComponent implements OnInit {
  text = {
    title: this.i18n.t('js.deutsche_bahn.link_document_version'),
    close_popup: this.i18n.t('js.close_popup_title'),
    currentlyExisting: this.i18n.t('js.deutsche_bahn.current_existing_versions'),
    newVersionExplanation: this.i18n.t('js.deutsche_bahn.new_version_explanation'),
    inputLabel: this.i18n.t('js.deutsche_bahn.document_version'),
    buttons: {
      link: this.i18n.t('js.deutsche_bahn.link'),
      abort: this.i18n.t('js.modals.button_cancel'),
    },
    newVersionNumber: this.i18n.t('js.deutsche_bahn.new_version_number'),
    new: this.i18n.t('js.deutsche_bahn.new'),
    existing: this.i18n.t('js.deutsche_bahn.existing'),
    linkSelection: {
      existing: this.i18n.t('js.deutsche_bahn.link_selection.existing'),
      copy: this.i18n.t('js.deutsche_bahn.link_selection.copy'),
      upload: this.i18n.t('js.deutsche_bahn.link_selection.upload'),
      explanation: this.i18n.t('js.deutsche_bahn.link_selection.explanation'),
    },
    errors: {
      required: this.i18n.t('js.deutsche_bahn.errors.document_version_required'),
      taken: this.i18n.t('js.deutsche_bahn.errors.document_version_taken'),
      multiple_uploaded: this.i18n.t('js.deutsche_bahn.errors.multiple_uploaded'),
    },
  };

  availableVersions:Array<WorkPackageResource>|null = null;

  takenVersionNumbers:Array<number>;

  selectedVersion:WorkPackageResource|null;

  linkMode:string|undefined;

  newVersionNumber:string;

  attachmentUploadDummyWP:WorkPackageResource;

  attachmentId:number;

  private submitted = false;

  $attachment:Observable<IAttachment>;

  $attachmentSet:Observable<boolean>;

  @ViewChild('newVersionNumberModel', { static: true }) newVersionNumberModel:NgModel;

  @ViewChild('selectedVersionModel', { static: true }) selectedVersionModel:NgModel;

  constructor(readonly elementRef:ElementRef,
    @Inject(OpModalLocalsToken) readonly locals:OpModalLocalsMap,
    readonly cdRef:ChangeDetectorRef,
    readonly i18n:I18nService,
    protected apiV3Service:ApiV3Service,
    private dbConfiguration:DbConfigurationService,
    private loadingIndicator:LoadingIndicatorService,
    private readonly attachmentsResourceService:AttachmentsResourceService,
    private halResourceService:HalResourceService) {
    super(locals, cdRef, elementRef);
  }

  ngOnInit():void {
    super.ngOnInit();

    this.attachmentUploadDummyWP = this.halResourceService
      .createHalResourceOfType('WorkPackage', { id: 'new', _type: 'WorkPackage' });

    this.loadingIndicator.indicator('modal').promise = this.setup();

    this.$attachment = this
      .attachmentsResourceService
      .collection(this.attachmentUploadDummyWP.id!)
      .pipe(
        this.untilDestroyed(),
        map((collection) => {
          this.attachmentId = collection[0] && collection[0].id as number;
          return collection[0];
        }),
      );

    this.$attachmentSet = this
      .$attachment
      .pipe(
        this.untilDestroyed(),
        map((attachment) => !!attachment),
      );
  }

  public submit():void {
    this.submitted = true;
    this.closeMe();
  }

  public displayedVersionNumber(workPackage:WorkPackageResource) {
    return (`000${workPackage[this.versionCfName]}`).slice(-3);
  }

  public displayedStatusName(workPackage:WorkPackageResource) {
    return workPackage.status.name;
  }

  public statusHtmlClass(workPackage:WorkPackageResource) {
    return `__hl_dot_status_${workPackage.status.id}`;
  }

  public hasTermInVersionCf() {
    // Without binding, we would not have access to the versionCfName.
    const compareFunc = (term:string, workPackage:WorkPackageResource) => this.displayedVersionNumber(workPackage).includes(term);

    return compareFunc.bind(this);
  }

  public get userSelection():DocumentVersionLinkingSelection|undefined {
    if(!this.submitted) {
      return undefined;
    }

    switch (this.linkMode) {
      case 'existing':
        return {
          mode: 'existing',
          chosenVersion: this.selectedVersion![this.versionCfName],
        };
      case 'copy':
        return {
          mode: 'copy',
          chosenVersion: this.selectedVersion![this.versionCfName],
          newVersion: parseInt(this.newVersionNumber),
        };
      case 'upload':
        return {
          mode: 'upload',
          newVersion: parseInt(this.newVersionNumber),
          attachmentId: this.attachmentId,
        };
      default:
        return undefined;
    }
  }

  public get versionsLoaded() {
    return !!this.availableVersions;
  }

  public get versionsExist() {
    return this.versionsLoaded && this.availableVersions!.length;
  }

  public get versionCfName() {
    return this.dbConfiguration.versionCustomFieldName();
  }

  public get displayNewVersionErrorMessage() {
    return this.displayErrorMessage(this.newVersionNumberModel);
  }

  public get displaySelectedVersionErrorMessage() {
    return this.displayErrorMessage(this.selectedVersionModel);
  }

  public get displayUploadNotificationMessage() {
    if (this.attachmentUploadDummyWP && this.attachmentUploadDummyWP.attachments.elements.length > 1) {
      return this.text.errors.multiple_uploaded;
    }
    return '';
  }

  private displayErrorMessage(model:NgModel) {
    if (!this.availableVersions || !model || !model.errors) {
      return '';
    }
    if (model.errors.versionTaken) {
      return this.text.errors.taken;
    }
    if (model.errors.required) {
      return this.text.errors.required;
    }
    return '';
  }

  public get isSubmittable() {
    return this.versionsLoaded
      && (!this.newVersionNumberModel || this.newVersionNumberModel.valid)
      && (!this.selectedVersionModel || this.selectedVersionModel.valid)
      && (this.linkMode !== 'upload' || this.attachmentId);
  }

  private setup() {
    return this
      .fetchVersions()
      .then((query) => {
        if (!query) { return; }

        this.availableVersions = query.results.elements;
        this.selectedVersion = this.availableVersions[0];

        this.takenVersionNumbers = this.availableVersions.map((version) => version[this.versionCfName]);
        this.newVersionNumber = ((this.takenVersionNumbers[0] || 0) + 1).toString();

        this.linkMode = this.versionsExist ? 'existing' : 'upload';
        this.cdRef.detectChanges();
      });
  }

  private get workPackageProcessId() {
    if (!this.processIdCustomFieldName) {
      alert('The installation/project is misconfigured. There is no custom field denoting the process_id active in this project.');
      return;
    }

    return this.locals.workPackage[this.processIdCustomFieldName].id;
  }

  private fetchVersions() {
    const props = {
      filters: JSON.stringify(this.versionsFilters),
      sortBy: JSON.stringify(this.versionsSortBy),
      pageSize: 1000,
    };

    return this
      .apiV3Service
      .queries
      .find(props, undefined, this.workPackageProjectIdentifier)
      .toPromise();
  }

  private get workPackageProjectIdentifier() {
    // Use the id instead of the identifier as the project will not be loaded
    // when coming from the wp list.
    return this.locals.workPackage.project.id;
  }

  private get processIdCustomFieldName() {
    return this.dbConfiguration.processIdCustomFieldName(this.locals.workPackage);
  }

  private get versionsFilters() {
    const filters = [];
    filters.push(this.typeFilter);
    filters.push(this.processIdFilter);

    return filters;
  }

  private get versionsSortBy() {
    return [[this.versionCfName, 'desc']];
  }

  private get typeFilter() {
    const typeId = this.locals.typeId;
    return { type: { operator: '=', values: [typeId] } };
  }

  private get processIdFilter() {
    const processIdFilter:any = {};

    processIdFilter[this.processIdCustomFieldName] = { operator: '=', values: [this.workPackageProcessId] };

    return processIdFilter;
  }
}
