import {Component, OnInit} from '@angular/core';
import {DbPathHelperService} from '../../common/path-helper.service';
import {ToastService} from 'core-app/shared/components/toaster/toast.service';
import {HalResourceService} from 'core-app/features/hal/services/hal-resource.service';

const componentSelector = 'db-webhook-button';

@Component({
  selector: componentSelector,
  templateUrl: './webhook-button.html'
})
export class DbWebhookButtonComponent implements OnInit {
  constructor(protected pathHelper:DbPathHelperService,
              private notifications:ToastService,
              private halResource:HalResourceService) { }

  ngOnInit() {
  }

  public activateWebhook() {
    this.halResource
      .post<any>(this.pathHelper.webhook)
      .toPromise()
      .then(() => {
        this.notifications.addSuccess('Started');
      })
      .catch((response) => {
        this.notifications.addError('No no no');
      });
  }
}
